import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('f-page-header',{attrs:{"title":_vm.title,"prevLinks":_vm.prevLinks}}),_c(VContainer,[_c(VCard,{staticClass:"mb-4"},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('labels.tsmList'))+" "),_c(VSpacer)],1),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.tsmList,"loading":_vm.loading,"no-data-text":_vm.$t('labels.noData'),"items-per-page":10},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.email",fn:function({ item }){return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.region",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getRegionDisplay(item))+" ")]}},{key:"item.actions",fn:function({ item }){return [_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}])})],1)],1)],1),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.dialogTitle)+" ")]),_c(VCardText,[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.editedItem.regionType === 'LAAM')?_c(VSelect,{attrs:{"items":_vm.countriesForSelect,"label":_vm.$t('labels.selectCountries'),"multiple":"","chips":"","item-text":"text","item-value":"value","rules":[
              (v) => (!!v && v.length > 0) || _vm.$t('validations.required'),
            ],"required":""},model:{value:(_vm.editedItem.countries),callback:function ($$v) {_vm.$set(_vm.editedItem, "countries", $$v)},expression:"editedItem.countries"}}):_vm._e(),(_vm.editedItem.regionType === 'BR')?_c(VSelect,{attrs:{"items":_vm.brStates,"label":_vm.$t('labels.selectStates'),"multiple":"","chips":"","item-text":"text","item-value":"value","rules":[
              (v) => (!!v && v.length > 0) || _vm.$t('validations.required'),
            ],"required":""},model:{value:(_vm.editedItem.regions),callback:function ($$v) {_vm.$set(_vm.editedItem, "regions", $$v)},expression:"editedItem.regions"}}):_vm._e()],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"grey darken-1","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t('labels.cancel'))+" ")]),_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.valid},on:{"click":_vm.saveTsm}},[_vm._v(" "+_vm._s(_vm.$t('labels.save'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }