<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks" />

    <v-container>
      <v-card class="mb-4">
        <v-card-title>
          {{ $t('labels.tsmList') }}
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="tsmList"
            :loading="loading"
            :no-data-text="$t('labels.noData')"
            :items-per-page="10">
            <template slot="item.name" slot-scope="{ item }">
              {{ item.name }}
            </template>

            <template slot="item.email" slot-scope="{ item }">
              {{ item.email }}
            </template>

            <template slot="item.region" slot-scope="{ item }">
              {{ getRegionDisplay(item) }}
            </template>

            <template slot="item.actions" slot-scope="{ item }">
              <v-btn icon small @click="openEditDialog(item)">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

    <!-- Diálogo para adicionar/editar TSM -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <!-- Tipo de região (BR ou LAAM) -->
            <!-- Seleção de países para LAAM -->
            <v-select
              v-if="editedItem.regionType === 'LAAM'"
              v-model="editedItem.countries"
              :items="countriesForSelect"
              :label="$t('labels.selectCountries')"
              multiple
              chips
              item-text="text"
              item-value="value"
              :rules="[
                (v) => (!!v && v.length > 0) || $t('validations.required'),
              ]"
              required></v-select>

            <!-- Seleção de estados para BR -->
            <v-select
              v-if="editedItem.regionType === 'BR'"
              v-model="editedItem.regions"
              :items="brStates"
              :label="$t('labels.selectStates')"
              multiple
              chips
              item-text="text"
              item-value="value"
              :rules="[
                (v) => (!!v && v.length > 0) || $t('validations.required'),
              ]"
              required></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="closeDialog">
            {{ $t('labels.cancel') }}
          </v-btn>
          <v-btn color="primary" :disabled="!valid" @click="saveTsm">
            {{ $t('labels.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { UserType } from '@/helpers/user_utils';

export default {
  components: {},
  data() {
    return {
      loading: false,
      tsmList: [],
      headers: [
        { text: this.$t('labels.name'), value: 'name', sortable: true },
        { text: this.$t('labels.email'), value: 'email', sortable: true },
        { text: this.$t('labels.region'), value: 'region', sortable: true },
        { text: this.$t('labels.actions'), value: 'actions', sortable: false },
      ],
      dialog: false,
      dialogDelete: false,
      editMode: false,
      valid: false,
      dialogTitle: '',
      tsmUsersList: [],
      editedItem: {
        userId: null,
        name: '',
        email: '',
        regionType: 'BR',
        regions: [],
      },
      defaultItem: {
        userId: null,
        name: '',
        email: '',
        regionType: 'BR',
        regions: [],
      },
      itemToDelete: null,
      brStates: [],
      laamCountries: [],
      countries: [],
      brStates: [
        { text: 'Acre', value: 'AC' },
        { text: 'Alagoas', value: 'AL' },
        { text: 'Amapá', value: 'AP' },
        { text: 'Amazonas', value: 'AM' },
        { text: 'Bahia', value: 'BA' },
        { text: 'Ceará', value: 'CE' },
        { text: 'Distrito Federal', value: 'DF' },
        { text: 'Espírito Santo', value: 'ES' },
        { text: 'Goiás', value: 'GO' },
        { text: 'Maranhão', value: 'MA' },
        { text: 'Mato Grosso', value: 'MT' },
        { text: 'Mato Grosso do Sul', value: 'MS' },
        { text: 'Minas Gerais', value: 'MG' },
        { text: 'Pará', value: 'PA' },
        { text: 'Paraíba', value: 'PB' },
        { text: 'Paraná', value: 'PR' },
        { text: 'Pernambuco', value: 'PE' },
        { text: 'Piauí', value: 'PI' },
        { text: 'Rio de Janeiro', value: 'RJ' },
        { text: 'Rio Grande do Norte', value: 'RN' },
        { text: 'Rio Grande do Sul', value: 'RS' },
        { text: 'Rondônia', value: 'RO' },
        { text: 'Roraima', value: 'RR' },
        { text: 'Santa Catarina', value: 'SC' },
        { text: 'São Paulo', value: 'SP' },
        { text: 'Sergipe', value: 'SE' },
        { text: 'Tocantins', value: 'TO' },
      ],
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.user.list,
    }),
    prevLinks() {
      return [
        {
          title: this.$t('labels.settings'),
          route: {
            name: 'settings',
          },
        },
      ];
    },
    title() {
      return this.$t('settings.names.tsmManage');
    },
    countriesForSelect() {
      return this.countries.map((country) => ({
        text:
          (country.name &&
            (country.name[this.$i18n.locale] || country.name.en)) ||
          'País',
        value: country._id, // Garantir que o value seja o ID do país
      }));
    },
  },
  watch: {
    users: {
      handler(newValue) {
        if (newValue && newValue.length) {
          // Filtrar apenas usuários do tipo TSM
          this.tsmUsersList = newValue.filter(
            (user) => user.type === UserType.TSM
          );
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getTsmUsers();
    this.loadCountries();
    this.getTsmList();
  },
  methods: {
    async getTsmUsers() {
      this.loading = true;
      // Obter todos os usuários do sistema
      await this.$store.dispatch('user/getAllClean');
      this.loading = false;
    },

    async getTsmList() {
      this.loading = true;
      try {
        // Chamar a API para obter todos os TSMs configurados
        const response = await this.$store.dispatch('tsm/getAllTsm');
        this.tsmList = response || [];
      } catch (error) {
        this.$store.dispatch('dialog/show', {
          title: this.$t('alerts.error'),
          content: this.$t('alerts.errorLoadingData'),
          alert: true,
          accept: 'Ok',
        });
      } finally {
        this.loading = false;
      }
    },

    getRegionDisplay(item) {
      if (item.regionType === 'BR') {
        return `Brasil: ${item.regions
          .map((r) => this.getStateName(r))
          .join(', ')}`;
      } else {
        if (item.countries && item.countries.length > 0) {
          return `LAAM: ${item.countries
            .map((c) => this.getCountryName(c))
            .join(', ')}`;
        } else {
          return 'LAAM: Nenhum país selecionado';
        }
      }
    },

    getStateName(code) {
      const state = this.brStates.find((s) => s.value === code);
      return state ? state.text : code;
    },

    getCountryName(countryId) {
      // Verificar se temos a lista de países carregada
      if (this.countries && this.countries.length > 0) {
        // Buscar o país pelo ID
        const country = this.countries.find((c) => c._id === countryId);
        if (country && country.name) {
          // Usar o nome no idioma atual ou fallback para en/pt
          const locale = this.$i18n.locale || 'en';
          return (
            country.name[locale] ||
            country.name.en ||
            country.name.pt ||
            countryId
          );
        }
      }

      // Se não encontrar o país na lista, usar o ID como fallback
      return `País ID: ${countryId}`;
    },

    openEditDialog(item) {
      this.editMode = true;
      this.dialogTitle = this.$t('labels.editTsm');

      // Fazer uma cópia profunda do item para evitar modificar o original
      this.editedItem = JSON.parse(JSON.stringify(item));

      // Verificar se os valores das regiões correspondem aos valores dos estados
      if (this.editedItem.regionType === 'BR' && this.editedItem.regions) {
        // Verificar se os valores das regiões estão no formato esperado
        const regionValues = this.brStates.map((state) => state.value);

        // Se os valores das regiões não corresponderem aos valores dos estados,
        // pode ser necessário convertê-los
        const regionsFormatted = this.editedItem.regions.map((region) => {
          // Verificar se a região já é um código válido (ex: SP, RJ)
          if (regionValues.includes(region)) {
            return region;
          }

          // Se for um nome completo (ex: São Paulo), tentar converter para o código
          const stateByName = this.brStates.find(
            (state) => state.text.toLowerCase() === region.toLowerCase()
          );

          return stateByName ? stateByName.value : region;
        });

        this.editedItem.regions = regionsFormatted;
      }

      this.dialog = true;
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      });
      if (item.userId) {
        const userId = item.userId._id || item.userId;
        this.$store.dispatch('tsm/testUpdateUserData', userId);
      }
    },

    closeDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
      });
    },

    async saveTsm() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        try {
          // Preparar o objeto para salvar
          const tsmData = {
            userId: this.editedItem.userId._id || this.editedItem.userId,
            name: this.editedItem.name,
            email: this.editedItem.email,
            regionType: this.editedItem.regionType,
            regions:
              this.editedItem.regionType === 'BR'
                ? this.editedItem.regions
                : [],
            countries:
              this.editedItem.regionType === 'LAAM'
                ? this.editedItem.countries
                : [],
          };

          // Se for edição, incluir o ID do registro
          if (this.editMode && this.editedItem._id) {
            tsmData._id = this.editedItem._id;
          }

          // Salvar no store
          const resultado = await this.$store.dispatch(
            'tsm/updateTsmConfig',
            tsmData
          );

          this.$store.dispatch('dialog/show', {
            title: this.$t('alerts.success'),
            content: this.$t(
              this.editMode ? 'alerts.updateSuccess' : 'alerts.createSuccess'
            ),
            alert: true,
            accept: 'Ok',
          });

          await this.getTsmList();
          this.closeDialog();
          this.getTsmList();
        } catch (error) {
          this.$store.dispatch('dialog/show', {
            title: this.$t('alerts.error'),
            content: this.$t('alerts.errorSavingData'),
            alert: true,
            accept: 'Ok',
          });
        } finally {
          this.loading = false;
        }
      }
    },
    async loadCountries() {
      try {
        const countries = await this.$store.dispatch('tsm/getCountries');
        // Garantir que estamos armazenando um array
        this.countries = Array.isArray(countries) ? countries : [];
      } catch (error) {
        this.countries = [];
      }
    },
  },
};
</script>
